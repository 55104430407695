var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c("div", { staticClass: "max-w-7xl mx-auto" }, [
      _c("div", { staticClass: "lg:flex lg:items-center lg:justify-between" }, [
        _c("div", { staticClass: "flex-1 min-w-0" }, [
          _c(
            "h2",
            {
              staticClass:
                "md:text-lg text-left font-bold leading-tight text-orange-500 sm:text-3xl sm:leading-9 truncate",
            },
            [
              !_vm.isLoading
                ? _vm._t("header")
                : _c("div", { staticClass: "flex flex-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "w-8 h-8 mr-4" },
                      [_c("line-loader")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "w-64 h-8 mr-4" },
                      [_c("line-loader")],
                      1
                    ),
                  ]),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap",
            },
            [
              !_vm.isLoading
                ? _vm._t("meta")
                : _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    _vm._l(_vm.desiredMetaElements, function (item) {
                      return _c(
                        "div",
                        {
                          key: item,
                          staticClass: "w-32 h-4 mr-2 mt-2 overflow-hidden",
                        },
                        [_c("line-loader")],
                        1
                      )
                    }),
                    0
                  ),
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "mt-5 flex lg:mt-0 lg:ml-4" },
          [_vm._t("actions")],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }