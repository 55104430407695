<template>
  <header>
    <div class="max-w-7xl mx-auto">
      <div class="lg:flex lg:items-center lg:justify-between">
        <div class="flex-1 min-w-0">
          <h2 class="md:text-lg text-left font-bold leading-tight text-orange-500 sm:text-3xl sm:leading-9 truncate">
            <slot v-if="!isLoading" name="header" />
            <div v-else class="flex flex-wrap">
              <div class="w-8 h-8 mr-4">
                <line-loader />
              </div>
              <div class="w-64 h-8 mr-4">
                <line-loader />
              </div>
            </div>
          </h2>
          <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
            <slot v-if="!isLoading" name="meta" />
            <div v-else class="flex flex-wrap">
              <div v-for="item in desiredMetaElements" :key="item" class="w-32 h-4 mr-2 mt-2 overflow-hidden">
                <line-loader />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 flex lg:mt-0 lg:ml-4">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'MetaActionsHeader',
  components: { LineLoader },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    desiredMetaElements: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style scoped>
.title-loader {
  max-width: 600px;
}
</style>
